export const fontSizes = [
    12,
    14,
    16,
    20,
    24,
    32,
    48,
    52,
    80,
    90,
    110
]